@import url("https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

/*BASIC STUFF*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

body {
  font-family: "Saira", sans-serif;
  background-color: black;
  width: 100%;
}

.modal-background-color .modal-content {
  color: #92989d;
  background-color: #282b31 !important;
}

.modal-card-color .form-control {
  color: #92989d;
  background-color: black;
}

.custom-modal-style {
  width: 1050px;
  height: auto;
  color: white;
  background-color: black;
}

.custom-modal-style-13 {
  width: 1050px;
  height: auto;
  color: white;
  background-color: black;
}

.custom-modal-style-pdf {
  width: 1050px !important;
  height: auto;
}

.react-image-dot__wrapper {
  background-size: cover;
  position: relative;
  background-color: gray;
}

.react-image-dot {
  background-color: red;
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  transform: translate(-5px, -5px);
  cursor: move; /* Fallback for older browsers */
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.react-image-dot__grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

#calculator {
  font-family: "Arimo";
  width: 90%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}

#calculator > div {
  display: flex;
  width: 100%;
  padding: 100px;
  background-color: rgba(20, 20, 20, 0.75);
  backdrop-filter: blur(10px);
  border-radius: 20px;
}

#firstcalc > div {
  width: 24%;
}

#calcinfo,
#calcinfo1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#firstcalcoutputs > div {
  width: 25%;
}

#firstcalcoutputs > :nth-child(1) {
  width: 50%;
}

#firstcalc,
#firstcalcoutputs {
  margin-bottom: 20px;
}

#firstcalc {
  min-height: 700px;
}

#firstcalcoutputs {
  min-height: 400px;
}

#generalcalc > div {
  width: 25%;
}

#generalcalc > :nth-child(2) {
  width: 50%;
}

#generalcalc {
  margin-bottom: 50px;
  position: relative;
  min-height: 450px;
}

#generalcalc1 {
  position: relative;
  min-height: 450px;
}

#generalcalc1 > div {
  width: 25%;
}

#generalcalc1 > :nth-child(2) {
  width: 50%;
}

#calculator > div div label:first-child {
  font-size: 2.5em;
  font-family: "Saira";
  color: rgb(153, 153, 153);
}

#timerange1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#calculator > div div label {
  width: 85%;
  border-radius: 10px;
}

#bottomtext {
  margin-top: 100px;
}

#calculator div div {
  display: flex;
  flex-direction: column;
}

.slider {
  width: 80%;
}

.radiolabel input {
  opacity: 0;
  display: none;
}

.radiolabel {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  font-size: 2em;
  transition: 0.25s ease-in-out;
}

.customradio,
.customradio1,
.customradio2,
.customradio3,
.customradio4,
.customradio5 {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.15);
  border: rgba(255, 255, 255, 0.15) 3px solid;
  margin-right: 20px;
  transition: 0.25s ease-in-out;
}

.radiolabel:hover .customradio,
.radiolabel:hover .customradio1,
.radiolabel:hover .customradio2,
.radiolabel:hover .customradio3,
.radiolabel:hover .customradio4,
.radiolabel:hover .customradio5 {
  border: rgb(255, 199, 44) 3px solid;
}

.radiolabel:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.selected {
  background-color: rgb(255, 199, 44);
}

#timeslider output,
#timeslider1 output {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 125px;
  text-align: center;
  padding: 5px;
  font-size: 4em;
  border: 2px rgb(50, 50, 50) solid;
  border-radius: 10px;
  background-color: rgb(17, 17, 17);
}

#custom-slider,
#custom-slider1 {
  width: 80%;
  height: 5px;
  background-color: rgb(65, 65, 65);
  display: flex;
}

#fill,
#fill1 {
  background-color: rgb(255, 199, 44);
  width: 50%;
  transition: 0.25s ease-in-out;
}

.slider {
  -webkit-appearance: none;
  width: 80%;
  border-radius: 5px;
  z-index: 10;
}

.slider::-webkit-slider-runnable-track {
  background-color: rgb(15, 15, 15);
  padding: 5px;
  transition: 0.5s ease-in-out;
}
.slider::-webkit-slider-runnable-track:hover {
  background-color: rgb(10, 10, 10);
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 10px;
  border-radius: 20%;
  background: rgb(71, 71, 71);
  transition: 0.5s ease-in-out;
}

.slider::-webkit-slider-thumb:hover {
  -webkit-appearance: none;
  background: rgb(255, 199, 44);
}

.slider {
  -moz-appearance: none;
  width: 80%;
  border-radius: 5px;
  z-index: 10;
}

.slider::-moz-range-track {
  background-color: rgb(15, 15, 15);
  padding: 10px;
  transition: 0.5s ease-in-out;
}
.slider::-moz-range-track:hover {
  background-color: rgb(10, 10, 10);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  -moz-appearance: none;
  height: 20px;
  width: 10px;
  border-radius: 20%;
  background: rgb(71, 71, 71);
  transition: 0.5s ease-in-out;
}

.slider::-moz-range-thumb:hover {
  -moz-appearance: none;
  background: rgb(255, 199, 44);
}

#calculatedtime1,
#calculateddose1,
#calculateddose2,
#calculatedtime2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 300px;
  text-align: center;
  padding: 5px;
  font-size: 4em;
  color: rgb(255, 199, 44);
  border: 2px rgb(50, 50, 50) solid;
  border-radius: 10px;
  background-color: rgb(17, 17, 17);
}

#calculatedtime3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 180px;
  height: 70px;
  text-align: center;
  padding: 2px;
  font-size: 2em;
  color: rgb(255, 199, 44);
  border: 2px rgb(50, 50, 50) solid;
  border-radius: 10px;
  background-color: rgb(17, 17, 17);
}

#calculatedequal1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 55px;
  width: 70px;
  height: 70px;
  text-align: center;
  padding: 2px;
  font-size: 2em;
}

#calculatedtime4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 180px;
  height: 70px;
  text-align: center;
  padding: 2px;
  font-size: 2em;
  color: rgb(255, 199, 44);
  border: 2px rgb(50, 50, 50) solid;
  border-radius: 10px;
  background-color: rgb(17, 17, 17);
}

#calculateddose3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 180px;
  height: 70px;
  text-align: center;
  padding: 2px;
  font-size: 2em;
  color: rgb(255, 199, 44);
  border: 2px rgb(50, 50, 50) solid;
  border-radius: 10px;
  background-color: rgb(17, 17, 17);
}

#calculateddose4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 180px;
  height: 70px;
  text-align: center;
  padding: 2px;
  font-size: 2em;
  color: rgb(255, 199, 44);
  border: 2px rgb(50, 50, 50) solid;
  border-radius: 10px;
  background-color: rgb(17, 17, 17);
}

#calculatedtreatments1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100px;
  height: 70px;
  text-align: center;
  padding: 2px;
  font-size: 2em;
  color: rgb(255, 199, 44);
  border: 2px rgb(50, 50, 50) solid;
  border-radius: 10px;
  background-color: rgb(17, 17, 17);
}

#calculatedtime1 {
  width: 550px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 5em;
}

#timerange1 {
  width: 100% !important;
  margin-bottom: 30px;
}

#timerange1 label {
  font-size: 5em;
  font-family: "Saira";
  color: rgb(153, 153, 153);
}

label span {
  color: rgb(255, 255, 255);
}

hr {
  display: none;
}

#treatementinstructions {
  text-align: left;
}

#calculateBtn,
#viewDose,
#viewCalc1,
#viewCalc2,
#resetCalc {
  display: block;
  background-color: rgb(20, 20, 20);
  width: 500px;
  padding: 30px;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
  user-select: none;
  margin: 20px;
}

#calculateBtn:hover,
#viewDose:hover,
#viewCalc1:hover,
#viewCalc2:hover,
#resetCalc:hover {
  cursor: pointer;
  background-color: rgb(50, 50, 50);
}

#calculateBtn {
  color: rgb(255, 199, 44);
}

#calculateBtn:hover {
  color: rgb(255, 164, 0);
  background-color: rgb(15, 15, 15);
}

#firstCalcButtons {
  display: flex;
  width: 25%;
  align-items: flex-start;
  justify-content: flex-start;
}

#viewDose {
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  line-height: 150px;
}

#tissuecolor label {
  position: relative;
}

#i,
#ii,
#iii,
#iv,
#v,
#vi {
  margin-left: 70px;
  width: 30px;
  height: 20px;
  position: absolute;
  left: 120px;
  border-radius: 5px;
}

#i {
  background-color: #f6cfb1;
}

#ii {
  background-color: #e8b590;
}

#iii {
  background-color: #d29f7c;
}

#iv {
  background-color: #bb7952;
}

#v {
  background-color: #a55e2b;
}

#vi {
  background-color: #3b1e1a;
}

/*PC - 2560x1440 to FHD*/
@media (max-width: 2560px) {
  #calculator {
    padding-top: 10px;
  }
  #calculator > div {
    width: 90%;
    border-radius: 15px;
    padding: 25px;
  }
  #firstcalc,
  #firstcalcoutputs {
    margin-bottom: 20px;
  }
  #firstcalc {
    min-height: 380px;
  }
  #firstcalcoutputs {
    min-height: 200px;
  }
  #generalcalc {
    margin-bottom: 50px;
    min-height: 240px;
  }
  #generalcalc1 {
    min-height: 240px;
  }
  p {
    font-size: 1.3em;
  }
  #calculator > div div label:first-child {
    font-size: 1.3em;
  }
  .radiolabel {
    font-size: 1.25em;
  }
  .customradio,
  .customradio1,
  .customradio2,
  .customradio3,
  .customradio4,
  .customradio5 {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 10px;
  }
  #timeslider output,
  #timeslider1 output {
    width: 80px;
    font-size: 2em;
  }
  #calculatedtime1,
  #calculateddose1,
  #calculateddose2,
  #calculatedtime2 {
    width: 175px;
    font-size: 2em;
  }
  #calculatedtime1 {
    width: 250px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 2.5em;
  }
  #timerange1 {
    width: 100% !important;
    margin-bottom: 30px;
  }
  #timerange1 label {
    font-size: 3em;
    font-family: "Saira";
    color: rgb(153, 153, 153);
  }
  #calculateBtn,
  #viewDose,
  #viewCalc1,
  #viewCalc2,
  #resetCalc {
    width: 300px;
    padding: 15px;
    border-radius: 10px;
    transition: 0.5s ease-in-out;
    user-select: none;
    margin: 20px;
  }
  #viewDose {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 100%;
  }
  #i,
  #ii,
  #iii,
  #iv,
  #v,
  #vi {
    margin-left: 20px;
  }

  /*LAPTOP*/
  @media (max-width: 1440px) {
    #calculator {
      padding-top: 10px;
    }
    #calculator > div {
      width: 100%;
      border-radius: 15px;
      padding: 25px;
    }
    #firstcalc,
    #firstcalcoutputs {
      margin-bottom: 20px;
    }
    #generalcalc {
      margin-bottom: 50px;
    }
    h1 {
      font-size: 3em;
    }
    p {
      width: 100%;
      font-size: 1.2em;
    }
    #calculator > div div label:first-child {
      font-size: 1.2em;
    }
    .radiolabel {
      font-size: 1.2em;
    }
    .customradio,
    .customradio1,
    .customradio2,
    .customradio3,
    .customradio4,
    .customradio5 {
      width: 18px;
      height: 18px;
      border-radius: 9px;
      margin-right: 8px;
    }
    #timeslider output,
    #timeslider1 output {
      width: 60px;
      font-size: 1.5em;
    }
    #calculatedtime1,
    #calculateddose1,
    #calculateddose2,
    #calculatedtime2 {
      width: 125px;
      font-size: 1.5em;
    }
    #calculatedtime3 {
      width: 125px;
      font-size: 1.5em;
    }

    #calculatedequal1 {
      width: 55px;
      font-size: 1em;
    }

    #calculatedtime4 {
      width: 125px;
      font-size: 1.5em;
    }
    #calculateddose3 {
      width: 125px;
      font-size: 1.5em;
    }

    #calculateddose4 {
      width: 125px;
      font-size: 1.5em;
    }

    #calculatedtreatments1 {
      width: 95px;
      font-size: 1.5em;
    }

    #calculatedtime1 {
      width: 250px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 2em;
    }
    #timerange1 {
      width: 40% !important;
      margin-bottom: 30px;
    }
    #timerange1 label {
      font-size: 2em;
      font-family: "Saira";
      color: rgb(153, 153, 153);
    }
  }

  /*TABLET*/
  @media (max-width: 1024px) {
    #calculator {
      padding-top: 10px;
      background-size: 130%;
      background-position-y: 25%;
      width: 100%;
    }
    #calculator > div {
      width: 60%;
      border-radius: 15px;
      padding: 25px;
      flex-direction: column;
      justify-content: center;
    }
    #adapter,
    #treatement,
    #timerange1,
    #adaptergeneral,
    #adaptergeneral1,
    #timeslider,
    #timeslider1,
    #objective,
    #painlevel,
    #tissuecolor,
    #treatmentcount,
    #doserange1 {
      margin-bottom: 30px;
    }
    #firstcalc,
    #firstcalcoutputs {
      margin-bottom: 20px;
    }
    #firstcalc {
      min-height: unset;
    }
    h1 {
      font-size: 3em;
    }
    p {
      width: 80%;
      font-size: 1.2em;
    }
    #calculator > div div label:first-child {
      font-size: 1.2em;
    }
    .radiolabel {
      font-size: 1.2em;
    }
    .customradio,
    .customradio1,
    .customradio2,
    .customradio3,
    .customradio4,
    .customradio5 {
      width: 18px;
      height: 18px;
      border-radius: 9px;
      margin-right: 8px;
    }
    #timeslider output,
    #timeslider1 output {
      width: 60px;
      font-size: 1.5em;
    }
    #calculatedtime1,
    #calculateddose1,
    #calculateddose2,
    #calculatedtime2 {
      width: 125px;
      font-size: 1.5em;
      margin-left: auto;
      margin-right: auto;
    }
    #calculatedtime1 {
      width: 250px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 1.5em;
      text-align: center;
    }
    #timerange1 {
      width: 60% !important;
      margin-bottom: 30px;
    }
    #timerange1 label {
      font-size: 1.75em;
      font-family: "Saira";
      color: rgb(153, 153, 153);
      text-align: center;
    }
    #firstcalc > div {
      width: 100%;
    }
    #doserange2,
    #timerange2 {
      text-align: center;
    }
    #generalcalc > div,
    #generalcalc1 > div {
      width: 100%;
    }
    #generalcalc > :nth-child(2),
    #generalcalc1 > :nth-child(2) {
      width: 100%;
    }
    #calculator > div div label {
      width: 100%;
    }
    #firstcalcoutputs > div {
      width: 100%;
      text-align: center;
    }
    #firstcalcoutputs {
      align-items: center;
    }
    #firstcalcoutputs > :nth-child(1) {
      width: 100%;
      text-align: center;
    }
    .slider {
      width: 100%;
    }
    #custom-slider,
    #custom-slider1 {
      width: 100%;
    }
    hr {
      display: inherit;
      margin-top: 25px;
      border-top: 0.5px solid rgb(80, 80, 80);
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
    }
    #treatementinstructions {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    #firstCalcButtons {
      flex-direction: column;
      margin-bottom: 10px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
    }

    #viewDose {
      align-items: center;
      justify-content: center;
      margin-top: 0px;
      line-height: 30px;
    }
    #calculateBtn,
    #viewDose,
    #viewCalc1,
    #viewCalc2,
    #resetCalc {
      width: 300px;
      padding: 15px;
      border-radius: 10px;
      transition: 0.5s ease-in-out;
      user-select: none;
      margin: 5px;
    }
  }

  /*MOBILE*/
  @media (max-width: 600px) {
    #calculator {
      padding-top: 10px;
      background-size: 200%;
      background-position-y: 30%;
    }
    #calculator > div {
      width: 80%;
      border-radius: 15px;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
    }
    #adapter,
    #treatement,
    #timerange1,
    #adaptergeneral,
    #adaptergeneral1,
    #timeslider,
    #timeslider1,
    #objective,
    #painlevel,
    #tissuecolor,
    #treatmentcount,
    #doserange1 {
      margin-bottom: 20px;
    }

    #objective {
      padding: 0px !important;
    }

    #firstcalc,
    #firstcalcoutputs {
      margin-bottom: 20px;
    }
    #generalcalc {
      margin-bottom: 50px;
    }
    h1 {
      font-size: 2em;
    }
    p {
      width: 80%;
      font-size: 1em;
    }
    #calculator > div div label:first-child {
      font-size: 1em;
    }
    .radiolabel {
      font-size: 1em;
    }
    .customradio,
    .customradio1,
    .customradio2,
    .customradio3,
    .customradio4,
    .customradio5 {
      width: 18px;
      height: 18px;
      border-radius: 9px;
      margin-right: 8px;
    }
    #timeslider output,
    #timeslider1 output {
      width: 60px;
      font-size: 1.5em;
    }
    #calculatedtime1,
    #calculateddose1,
    #calculateddose2,
    #calculatedtime2 {
      width: 125px;
      font-size: 1.5em;
    }
    #calculatedtime1 {
      width: 165px;
    }
    #firstcalc > div {
      width: 100%;
    }
    #generalcalc > div,
    #generalcalc1 > div {
      width: 100%;
    }
    #generalcalc > :nth-child(2),
    #generalcalc1 > :nth-child(2) {
      width: 100%;
    }
    #calculator > div div label {
      width: 100%;
    }
    .slider {
      width: 100%;
    }
    #custom-slider,
    #custom-slider1 {
      width: 100%;
    }
    hr {
      display: inherit;
      margin-top: 15px;
      border-top: 0.5px solid rgb(80, 80, 80);
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
    }
    #calculateBtn,
    #viewDose,
    #viewCalc1,
    #viewCalc2,
    #resetCalc {
      width: 250px;
    }
    #calculatedtime1 {
      width: 200px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 1.5em;
      text-align: center;
    }
    #timerange1 {
      width: 80% !important;
      margin-bottom: 30px;
    }
    #timerange1 label {
      font-size: 1.75em;
      font-family: "Saira";
      color: rgb(153, 153, 153);
      text-align: center;
    }
  }

  @media (max-width: 321px) {
    h1 {
      font-size: 1.35em;
    }
    p {
      font-size: 0.85em;
    }
    #calculateBtn,
    #viewDose,
    #viewCalc1,
    #viewCalc2,
    #resetCalc {
      width: 180px;
    }
  }
}
